import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import logo from "./assets/img/YTC.png";
import { Helmet } from "react-helmet";

const Footer = () => {
  return (
    <footer className="footer">
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>YTC TechVenture - Empowering Innovations</title>
        <meta name="description" content="YTC TechVenture is committed to delivering innovative technology solutions to empower businesses, foster growth, and drive efficiency. Contact us for reliable and scalable tech services." />
        <meta name="keywords" content="YTC TechVenture, Technology Solutions, Business Growth, Innovation, Scalable Tech Services" />
        <meta name="author" content="YTC TechVenture" />
      </Helmet>
      <div style={{ borderTop: "1px solid black", paddingTop: "20px" }}>
        <Container>
          <Row className="align-items-center">
            <Col xs={12} sm={6}>
              <img src={logo} alt="Logo" />
            </Col>
            <Col xs={12} sm={6} className="text-center text-sm-end">
              <div>
                <p><strong> Address:</strong> Mama Chauraha,  Raebareli Uttar Pradesh, 229001</p>
                <p><strong>Email:</strong> ytctechventures@gmail.com</p>
                <p><strong>Contact Number:</strong> 9779552562</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
